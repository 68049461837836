<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from '../../components/admin/list'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List
  },
  data() {
    return {
      title: "",
      level: '',
      state: 'normal'
    }
  },
  created() {
    this.level = this.$route.params.level
  },
  mounted() {
    let title = '운영자관리&nbsp'
    if (this.level === 'super') {
      title += '<span class="badge bg-primary">' + this.$t('최고관리자') + '</span>'
    } else if (this.level === 'admin') {
      title += '<span class="badge bg-success">' + this.$t('관리자') + '</span>'
    } else if (this.level === 'helpdesk') {
      title += '<span class="badge bg-info">' + this.$t('고객센터') + '</span>'
    }
    this.title = title
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <List :level="level" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
